body {
  padding: 20px;
}

h6 {
  color: #9b9b9b;
}

a {
  text-decoration: none;
  padding: 20px;
  border-radius: 5px;
  display: inline-block;
  margin: 5px;
}

a:hover {
  text-decoration: none;
}

.dropbox-folder {
  background-color: #f6f7f8;
}

.card {
  margin: 20px;
}

.process {
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

i {
  font-size: 14px;
  color: darkgray;
  margin-top: 10px;
  display: block;
}

input[type="text"],
input[type="date"],
.react-date-picker__wrapper {
  border: 1px solid #9b9b9b !important;
  border-radius: 5px;
  color: #9b9b9b;
  padding: 5px;
  margin-top: 5px;
  margin-right: 15px;
}

.react-date-picker__inputGroup__input {
  color: #9b9b9b;
}
